//const baseUrl = "https://localhost:44345/";

let baseUrl = "#{Licensing.Registration.Api.Url}";

function getBase() {
  if (baseUrl.indexOf("Licensing.Registration.Api.Url") > -1) {
 // baseUrl = "https://localhost:44345/";
   // baseUrl = "https://localhost:5001/";
 //   baseUrl = "https://eprapi.nvidia.com/";
    baseUrl = "https://eprapi-stg.nvidia.com/";
  }

  return baseUrl;
}

export const GET_ORG_DATA_FROM_PEC =
  getBase() + "api/userregistration/accountdetail";
export const GET_PEC_FROM_PAKID_SERIAL =
  getBase() + "api/userregistration/pakandpecsfdc";
export const GET_LOOKUPS = getBase() + "api/masterdata/masterdatacollection";
export const GET_STATE_FROM_COUNTRY =
  getBase() + "api/masterdata/statesbycountrycode";
export const POST_USER_REGISTRATION = getBase() + "api/UserRegistration";
export const GET_STATUS_PAK =
  getBase() + "api/userregistration/checkstatusbypak";
export const GET_STATUS_SERIAL =
  getBase() + "api/userregistration/checkstatusbyserialNo";
export const GET_RWAMAPPING = getBase() + "api/masterdata/rwamapping";
export const GET_ACTIVATIONTOKEN =
  getBase() + "api/userregistration/validatelgsactivationtoken";
export const POST_LGSEMAIL =
  getBase() + "api/userregistration/LGSValidateEmail";
export const ValidateEmailAPI =
  getBase() +"api/userregistration/EmailAPIValidation";
export const GET_NGCCreate =
  getBase() + "api/userregistration/checkevalngccreatebyproductfamily";


